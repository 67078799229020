import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FaCopy } from "react-icons/fa";
import airtime from "../images/airtime.svg";
import data from "../images/data.png";
import cable from "../images/cable.png";
import utility from "../images/utility.png";
import historyImage from "../images/history.png";
import withdraw from "../images/withdraw.png";
import earnings from "../images/earnings.svg";
import facebook from "../images/facebook.svg";
import whatsapp from "../images/whatsapp.svg";
import contacts from "../images/contacts.png";
import a2c from "../images/a2c.png";
import partner from "../images/partner.svg";
import download from "../images/download.svg";
import { useGlobalContext } from "../context/UserContext";
import WarningAlert from "../components/WarningAlert";
import { Modal } from "../components/Modal";
import FormInput from "../components/FormInput";
import KYCModals from "../Modals/KYCModal";

const DashBoard = () => {
  const { user, generateVpayAcc, kycDetails, isLoading } = useGlobalContext();
  const navigate = useNavigate();
  const websiteUrl = window.location.origin;
  const userName = encodeURIComponent(user.userName);
  let referralLink = `${websiteUrl}/register/${userName}`;
  const copyReferralLink = async () => {
    await window.navigator.clipboard.writeText(
      `${websiteUrl}/register/${userName}`
    );
    toast.success("Referral link copied");
  };
  const copyAccNo = async (number) => {
    window.navigator.clipboard.writeText(number);
    toast.success("Account number copied");
  };

  const [showAlert, setShowAlert] = useState(false);
  useEffect(() => {
    if (!isLoading && !user.nin && !user.bvn) {
      setKycModal(true);
    } else {
      setKycModal(false);
    }
  }, [isLoading, user.nin, user.bvn]);

  const navigation = [
    { name: "Airtime", image: airtime, link: "/profile/buyAirtime" },
    { name: "data", image: data, link: "/profile/buyData" },
    // { name: "Tv ", image: cable, link: "/profile" },
    { name: "Utility", image: utility, link: "/profile/electricity" },
    // { name: "Contacts", image: contacts, link: "/profile/contacts" },
    // { name: "History", image: historyImage, link: "/profile/transactions" },
    { name: "earnings", image: earnings, link: "/profile/earnings" },
    { name: "withdraw", image: withdraw, link: "/profile/withdraw" },
    {
      name: "A2C",
      image: a2c,
      link: "/profile/a2c",
      // msg: "coming soon",
    },
    {
      name: "partners ",
      image: partner,
      // link: "/profile",
      link: "/profile/partnership",
      // msg: "coming soon",
    },
  ];
  const pay_with_card = () => {
    if (user.reservedAccountNo3) {
      window.open(
        `https://topup.vpay.africa/${user.reservedAccountNo3}`,
        "blank"
      );
    } else {
      return;
    }
  };
  const [kycModal, setKycModal] = useState(false);
  return (
    <div className=" md:ml-[6rem] bg-white p-4 border-2 relative">
      {showAlert && <WarningAlert close={() => setShowAlert(false)} />}
      <div className="bg-[var(--primary-100)]  absolute top-0 left-0 right-0 px-2  ">
        <div className="flex justify-between items-center  space-x-4">
          <div className="">
            <p className=" text-lg font-bold text-center capitalize">
              {user.userName && user.userName.substring(0, 15)}
            </p>
            <small className="font-bold">
              {user.userName && `₦${user.balance.toFixed(2)}`}
            </small>
          </div>
          <div className="grid gap-1">
            <button
              onClick={() => (window.location.href = "#fundWallet")}
              className="btn text-xs"
            >
              fund your wallet
            </button>
            {!user.bvn && !user.nin && (
              <button
                className="btn btn-danger"
                onClick={() => setKycModal(true)}
              >
                update kyc
              </button>
            )}
            {kycModal && <KYCModals close={() => setKycModal(!kycModal)} />}
          </div>
        </div>
        {/* <div className="flex justify-center items-center p-2 gap-2">
          <p className="text-center text-sm ">
            <span className="font-bold">Referral link:</span> {referralLink}
          </p>
          <button className="btn btn-hipster" onClick={copyReferralLink}>
            copy
          </button>
        </div> */}
      </div>

      <section className="grid grid-cols-3  mt-16 md:grid-cols-4 items-center justify-center gap-4 cursor-pointer ">
        <>
          {navigation.map((e, index) => {
            // if (!e.name === "airtime")
            return (
              <div
                className=" border-2 border-[var(--primary-500)]  self-start  max-w-[200px] p-4  bg-white rounded-xl"
                key={index}
                onClick={() => navigate(`${e.link}`)}
              >
                <div className="max-w-[3rem] m-auto  ">
                  <img
                    className="img"
                    src={e.image}
                    alt={e.name}
                    // width={"200px"}
                  />
                </div>
                <p className="font-bold text-center capitalize">{e.name}</p>
                <p className="font-bold text-center capitalize text-red-500">
                  {e.msg}
                </p>
              </div>
            );
          })}
          <div
            className=" border-2 border-[var(--primary-500)]  self-start  max-w-[200px] p-4  bg-white rounded-xl"
            // key={index}
            onClick={() =>
              (window.location.href =
                "https://chat.whatsapp.com/CLsrIxOm4k7HK5nTf9M3WZ")
            }
          >
            <div className="max-w-[3rem] m-auto  ">
              <img
                className="img"
                src={whatsapp}
                alt="whatsapp"
                // width={"200px"}
              />
            </div>
            <p className="font-bold text-center capitalize">Whatsapp </p>
          </div>
          <a
            download={true}
            href="./assets/DataReloaded.apk"
            className=" border-2 border-[var(--primary-500)]  self-start  max-w-[200px] p-4  bg-white rounded-xl"
          >
            <div className="max-w-[3rem] m-auto  ">
              <img className="img" src={download} alt="whatsapp" />
            </div>
            <p className="font-bold text-center capitalize"> app </p>
          </a>
        </>
      </section>
      <h3 className="text-center font-bold mt-4 underline">Payment accounts</h3>
      <section className="md:flex justify-center gap-4  " id="fundWallet">
        <div className="card m-auto md:m-0 bg-[var(--primary-600)]  text-white ">
          <div className="w-100 bg-white rounded-lg">
            <p className="text-sm font-bold uppercase text-[#25d366]">
              bank charges is applied
            </p>
          </div>
          <p className="text-sm ">
            Account name <br /> DataReloaded-
            {user.userName && user.userName.substring(0, 10)}
          </p>
          {/* <div className="text-sm">
            <p>
              {user.reservedAccountBank} <b>{user.reservedAccountNo}</b>{" "}
              <FaCopy
                onClick={() => copyAccNo(user.reservedAccountNo)}
                className="copy__icon"
              />
            </p>
            <p>
              {user.reservedAccountBank2} <br />
              <b>{user.reservedAccountNo2}</b>
              <FaCopy
                onClick={() => copyAccNo(user.reservedAccountNo2)}
                className="copy__icon"
              />
            </p>
            <p className="text-xs capitalize opacity-60">
              All payments made to the above account number will automatically
              fund your wallet
            </p>
            {user.reservedAccountNo3 && (
              <button
                onClick={pay_with_card}
                className="btn btn-block btn-hipster "
              >
                Pay with ATM card instead
              </button>
            )}
          </div> */}
          <div className="flex">
            <div className="flex justify-center gap-2 flex-wrap">
              <p className="text-xs capitalize opacity-60">
                All payments made to the below account number will automatically
                fund your wallet
              </p>
              {user?.accountNumbers?.map((e, index) => (
                <div
                  key={index}
                  className="bg-white p-4 text-[var(--primary-700)] rounded-lg shadow-md font-bold text-center w-10/12 md:w-[30%] md:max-w-[300px]"
                >
                  <p>{e.bankName}</p>
                  <p>{e.accountNumber}</p>
                  <button
                    onClick={() => copyAccNo(user.reservedAccountNo)}
                    className="btn btn-hipster"
                  >
                    copy
                  </button>
                </div>
              ))}
              <div>
                <h1 className="sub__title">refer a friend</h1>
                <div className="note text-sm">
                  Refer people to DataReloaded and earn ₦500 immediately the
                  person upgrade his/her account to Reseller.
                </div>
                <button
                  className="btn btn-hipster mt-2"
                  onClick={copyReferralLink}
                >
                  Copy referral link
                </button>
                <p>{referralLink}</p>
                <div className=" ">
                  {user.userType === "smart earner" && (
                    <>
                      <p className="text-lg">Upgrade your account</p>
                      <button
                        className="btn text-white btn-hipster"
                        onClick={() => setShowAlert(true)}
                      >
                        Upgrade to reseller @ ₦1000
                      </button>
                    </>
                  )}
                </div>
              </div>{" "}
            </div>
          </div>
        </div>
        {/* <div className="card m-auto md:m-0 bg-[var(--red-light)]  font-semibold text-red-900">
          {user.reservedAccountBank3 ? (
            <>
              <div className="w-100 bg-white rounded-lg">
                <p className="text-sm text-[#25d366] uppercase">
                  1% charges is applied
                </p>
              </div>
              <h3 className="text-sm p-0 m-0">
                Account name: <br /> Abdullahi Onisabi DataReloaded-
                {user.userName && user.userName.substring(0, 10)}
              </h3>
              <div className="text-sm">
                <p>
                  {user.reservedAccountBank3} <b>{user.reservedAccountNo3}</b>{" "}
                  <FaCopy
                    onClick={() => copyAccNo(user.reservedAccountNo3)}
                    className="copy__icon"
                  />
                </p>

                <p className="text-xs capitalize opacity-60">
                  All payments made to the above account number will
                  automatically fund your wallet
                </p>
                {user.reservedAccountNo3 && (
                  <button
                    onClick={pay_with_card}
                    className="btn btn-block special__btn"
                  >
                    Pay with ATM card instead
                  </button>
                )}
              </div>
            </>
          ) : (
            <button
              onClick={() => generateVpayAcc()}
              className="btn btn-danger"
            >
              create Vpay account number{" "}
            </button>
          )}
        </div>
        {user.reservedAccountBank4 && (
          <div className="card m-auto md:m-0 bg-black/25  font-semibold text-[var(--primary-500)]">
            <div className="w-100 bg-white rounded-lg">
              <p className="text-sm text-[#25d366] uppercase">
                BEST FOR PAYMENT ABOVE ₦3000 <span>₦30 charges</span>
              </p>
            </div>
            <h3 className="text-sm p-0 m-0">
              Account name: <br />
              {user.userName && user.userName.substring(0, 10)}
            </h3>
            <div className="text-sm">
              <p>
                {user.reservedAccountBank4} <b>{user.reservedAccountNo4}</b>{" "}
                <FaCopy
                  onClick={() => copyAccNo(user.reservedAccountNo4)}
                  className="copy__icon"
                />
              </p>

              <p className="text-xs capitalize opacity-60">
                All payments made to the above account number will automatically
                fund your wallet
              </p>
              <button
                // onClick={() => navigate("/profile/fundWallet")}
                onClick={pay_with_card}
                className="btn btn-block special__btn"
              >
                Pay with ATM card instead
              </button>
            </div>
          </div>
        )} */}
        {/* <div className="card m-auto md:m-0 bg-[var(--primary-600)] text-white ">
          <h1 className="sub__title">refer a friend</h1>

          <div className="note text-sm">
            Refer people to DataReloaded and earn ₦500 immediately the person
            upgrade his/her account to Reseller.
          </div>
          <button className="btn btn-hipster mt-2" onClick={copyReferralLink}>
            Copy referral link
          </button>
          <p>{referralLink}</p>
          <div className=" ">
            {user.userType === "smart earner" && (
              <>
                <p className="text-lg">Upgrade your account</p>
                <button
                  className="btn text-white btn-hipster"
                  onClick={() => setShowAlert(true)}
                >
                  Upgrade to reseller @ ₦1000
                </button>
              </>
            )}
          </div>
        </div> */}
      </section>
    </div>
  );
};

export default DashBoard;
